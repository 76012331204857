import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Badge } from 'src/components/ui/badge';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { IndianRupee } from 'lucide-react';

const InvoiceDetails = () => {
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const subtotal = candidates.reduce(
    (sum, candidate) => sum + candidate.amount,
    0
  );
  const gstAmount = subtotal * (18 / 100);
  const total = subtotal + gstAmount;

  const breadcrumbItems = [
    { label: 'Home', href: '/customer/dashboard' },
    { label: 'Invoices', href: '/customer/invoice' },
    { label: 'Invoice Detail', href: '/customer/invoice' },
  ];

  useEffect(() => {
    const invoiceDataObj = {
      invoiceNumber: 'INV0011',
      invoiceDate: '01 Aug 2023',
      dueDate: '01 Oct 2023',
      status: 'Paid',
      address: {
        name: 'AHEDGE',
        street: '1205 Rangsharda, Reclamation',
        city: 'Bandra West, Mumbai',
        state: 'Maharashtra',
        zip: '400050',
        country: 'India',
      },
      candidates: [
        {
          name: 'Aarav Patel',
          referenceNo: 'REF001',
          dateOfJoining: '01 Aug 20231',
          amount: 75000,
        },
        {
          name: 'Diya Sharma',
          referenceNo: 'REF002',
          dateOfJoining: '12 Aug 2023',
          amount: 82000,
        },
        {
          name: 'Arjun Gupta',
          referenceNo: 'REF003',
          dateOfJoining: '01 Aug 2023',
          amount: 78000,
        },
        {
          name: 'Ananya Singh',
          referenceNo: 'REF004',
          dateOfJoining: '05 Aug 2023',
          amount: 80000,
        },
        {
          name: 'Vikram Reddy',
          referenceNo: 'REF005',
          dateOfJoining: '11 Aug 2023',
          amount: 85000,
        },
        {
          name: 'Priya Krishnan',
          referenceNo: 'REF006',
          dateOfJoining: '01 Sept 2023',
          amount: 79000,
        },
        {
          name: 'Rahul Desai',
          referenceNo: 'REF007',
          dateOfJoining: '01 Aug 2023',
          amount: 76000,
        },
        {
          name: 'Neha Kapoor',
          referenceNo: 'REF008',
          dateOfJoining: '21 Aug 2023',
          amount: 81000,
        },
        {
          name: 'Siddharth Chatterjee',
          referenceNo: 'REF009',
          dateOfJoining: '01 Aug 2023',
          amount: 83000,
        },
        {
          name: 'Meera Iyer',
          referenceNo: 'REF010',
          dateOfJoining: '01 Aug 2023',
          amount: 77000,
        },
        // Add more candidates as needed
      ],
    };
    setInvoiceData(invoiceDataObj);
    setCandidates(invoiceDataObj.candidates);
  }, [invoiceId]);

  if (!invoiceData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Invoice Detail
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="items-center justify-center shadow-sm">
        <Card className="w-full">
          <CardHeader className="flex flex-row justify-between items-start lightBlueBg rounded-lg">
            <div className="text-left">
              <CardTitle className="text-lg font-bold mb-2">
                {invoiceData.invoiceNumber}
              </CardTitle>
              <p className="text-sm text-muted-foreground">
                Invoice Date: {invoiceData.invoiceDate}
              </p>
              <p className="text-sm text-muted-foreground">
                Due Date: {invoiceData.dueDate}
              </p>
              <Badge
                className={`mt-2 ${
                  invoiceData.status.toLowerCase() === 'paid'
                    ? 'bg-green-500'
                    : 'bg-yellow-500'
                }`}
              >
                {invoiceData.status}
              </Badge>
            </div>
            <div className="text-right">
              <p className="text-sm font-bold">{invoiceData.address.name}</p>
              <p className="text-sm">{invoiceData.address.street}</p>
              <p className="text-sm">{invoiceData.address.city},</p>
              <p className="text-sm">
                {' '}
                {invoiceData.address.state} {invoiceData.address.zip}
              </p>
            </div>
          </CardHeader>
          <CardContent className="mt-5">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">No.</TableHead>
                  <TableHead>Candidate Name</TableHead>
                  <TableHead>Reference No</TableHead>
                  <TableHead>Date of Joining</TableHead>
                  <TableHead>Amount</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoiceData.candidates.map((candidate, index) => (
                  <TableRow key={candidate.referenceNo}>
                    <TableCell className="text-left font-medium">
                      {index + 1}
                    </TableCell>
                    <TableCell className="text-left">
                      {candidate.name}
                    </TableCell>
                    <TableCell className="text-left">
                      {candidate.referenceNo}
                    </TableCell>
                    <TableCell className="text-left">
                      {candidate.dateOfJoining}
                    </TableCell>
                    <TableCell className="text-left">
                      <span className="flex items-center">
                        <IndianRupee className="mr-1 h-4 w-4" />
                        {candidate.amount.toFixed(2)}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} className="text-right font-medium">
                    Subtotal:
                  </TableCell>
                  <TableCell className="text-left">
                    <span className="flex items-center">
                      <IndianRupee className="mr-1 h-4 w-4" />
                      {subtotal.toFixed(2)}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} className="text-right font-medium">
                    GST (18%):
                  </TableCell>
                  <TableCell className="text-left">
                    <span className="flex items-center">
                      <IndianRupee className="mr-1 h-4 w-4" />
                      {gstAmount.toFixed(2)}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} className="text-right font-bold">
                    Total:
                  </TableCell>
                  <TableCell className="text-left font-bold">
                    <span className="flex items-center">
                      <IndianRupee className="mr-1 h-4 w-4" />
                      {total.toFixed(2)}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default InvoiceDetails;
