import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Header from '../Header/Header';
import CustomSidebar from '../Sidebar/CustomerSidebar';

const CustomerLayout = () => {
  const { user } = useAuth();
  console.log('useruser ', user);

  return (
    <div className="flex h-screen overflow-hidden">
      <CustomSidebar />
      <div className="flex flex-1 flex-col overflow-hidden">
        <Header />
        <main className="flex flex-1 flex-col overflow-y-auto p-4 lg:gap-4 lg:p-4 border-l">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default CustomerLayout;
