import React from 'react';
import { Button } from 'src/components/ui/button';

const FilterButton = ({ filters, onFilterChange, defaultFilter }) => {
  const handleFilterClick = (filterValue) => {
    onFilterChange(filterValue);
  };

  return (
    <div className="w-full">
      <div className="flex items-center overflow-x-auto">
        <div className="flex space-x-2">
          {filters.map((filter) => (
            <Button
              key={filter.value}
              variant={defaultFilter === filter.value ? 'default' : 'outline'}
              className={`
                px-4 py-2
                border
                transition-colors duration-200
                whitespace-nowrap
                ${
                  defaultFilter === filter.value
                    ? 'border-primary bg-primary text-primary-foreground hover:bg-primary/90'
                    : 'border-primary bg-background text-primary hover:bg-primary/10'
                }
              `}
              onClick={() => handleFilterClick(filter.value)}
            >
              {filter.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
