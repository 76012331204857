import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { cn } from 'src/lib/utils';

import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';

const LocationFields = ({ control, errors, locations = [] }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'locations',
  });

  const formatValue = (value) => value?.toString() || undefined;

  const isLastFieldEmpty = (formFields) => {
    const lastField = formFields[formFields.length - 1];
    return !lastField?.location || !lastField?.count;
  };

  return (
    <div>
      <div className="flex items-center space-x-2 mt-1">
        <div className="w-1/2">
          <Label htmlFor="location">Location</Label>
          <span className="text-red-500 ml-1">*</span>
        </div>
        <div>
          <Label htmlFor="count">Headcount</Label>
          <span className="text-red-500 ml-1">*</span>
        </div>
      </div>

      {fields.map((field, index) => (
        <div key={field.id} className="flex items-center space-x-2 mb-4 mt-1">
          <div className="w-1/2">
            <Controller
              name={`locations.${index}.location`}
              control={control}
              rules={{ required: true }}
              render={({ field: locationField }) => (
                <Select
                  onValueChange={(value) =>
                    locationField.onChange(formatValue(value))
                  }
                  value={formatValue(locationField.value)}
                >
                  <SelectTrigger
                    className={cn(
                      'w-full',
                      errors?.locations?.[index]?.location && 'border-red-500'
                    )}
                  >
                    <SelectValue placeholder="Select Location" />
                  </SelectTrigger>
                  <SelectContent>
                    {locations.map((location) => (
                      <SelectItem
                        key={location.value}
                        value={formatValue(location.value)}
                      >
                        {location.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
          <div className="w-28">
            <Controller
              name={`locations.${index}.count`}
              control={control}
              rules={{ required: true }}
              render={({ field: countField }) => (
                <Input
                  type="number"
                  onChange={countField.onChange}
                  value={countField.value || ''}
                  className={cn(
                    errors?.locations?.[index]?.count && 'border-red-500'
                  )}
                />
              )}
            />
          </div>

          {fields.length > 1 && (
            <Button
              type="button"
              variant="outline"
              onClick={() => remove(index)}
            >
              -
            </Button>
          )}

          {index === fields.length - 1 && (
            <Button
              type="button"
              variant="outline"
              onClick={() => append({ location: '', count: '' })}
              disabled={isLastFieldEmpty(fields)}
            >
              +
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default LocationFields;
