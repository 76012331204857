import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import { Badge } from 'src/components/ui/badge';
import { Briefcase, GraduationCap } from 'lucide-react';

// Helper function to generate unique keys
const generateKey = (prefix, value, identifier) =>
  `${prefix}-${value.toLowerCase().replace(/\s+/g, '-')}-${identifier}`;

const Section = ({ title, children, className = 'mt-8' }) => (
  <div className={`relative ${className}`}>
    <div className="absolute -top-3 left-4 px-2 bg-background">
      <h4 className="font-bold pageTitle">{title}</h4>
    </div>
    <div className="rounded-lg border lightBlueBg shadow-sm px-4 py-7 border-gray-300 space-y-5 text-left">
      {children}
    </div>
  </div>
);

const ExperienceItem = ({ experience }) => (
  <div className="mb-2">
    <div className="flex items-center space-x-2">
      <Briefcase size={18} className="text-gray-600" />
      <span className="font-medium">
        {experience.company || 'Unknown Company'}
      </span>
    </div>
    <div className="text-sm text-gray-600 ml-6 mt-1">
      {experience.role || 'Unknown Role'} | {experience.years || 'N/A'} years
    </div>
  </div>
);

const EducationItem = ({ education }) => (
  <div className="mb-2">
    <div className="flex items-center space-x-2">
      <GraduationCap size={18} className="text-gray-600" />
      <span className="font-medium">
        {education.college || 'Unknown College'}
      </span>
    </div>
    <div className="text-sm text-gray-600 ml-6 mt-1">
      {education.degree || 'Unknown Degree'} - {education.year || 'N/A'}
    </div>
    <div className="text-sm text-gray-600 ml-6">
      Marks: {education.marks || 'N/A'}
    </div>
  </div>
);

const SkillBadge = ({ skill }) => <Badge variant="outline">{skill}</Badge>;

const ProfileView = () => {
  const { profileId } = useParams();
  const profiles = [
    {
      id: 1,
      name: 'Ananya Singh',
      title: 'Senior Data Scientist',
      avatar: 'https://example.com/ananya.jpg',
      phone: '+91 98765 43210',
      email: 'ananya.singh@example.com',
      address: '15, Bandra West, Mumbai, Maharashtra 400050',
      age: 30,
      gender: 'Female',
      education: [
        {
          college: 'Indian Statistical Institute, Kolkata',
          degree: 'M.Sc. Statistics',
          year: 2015,
          marks: '9.1 CGPA',
        },
        {
          college: 'Delhi University',
          degree: 'B.Sc. Mathematics',
          year: 2013,
          marks: '8.8 CGPA',
        },
      ],
      experience: [
        {
          company: 'Amazon India',
          role: 'Senior Data Scientist',
          years: 3,
        },
        { company: 'Flipkart', role: 'Data Scientist', years: 2 },
      ],
      currentStatus: 'Employed',
      availabilityToJoin: '2 months notice',
      skills: ['Python', 'R', 'Machine Learning', 'SQL', 'Tableau', 'AWS'],
    },
    {
      id: 2,
      name: 'Priya Krishnan',
      title: 'Full Stack Developer',
      avatar: 'https://example.com/priya.jpg',
      phone: '+91 87654 32109',
      email: 'priya.krishnan@example.com',
      address: '42, Anna Nagar, Chennai, Tamil Nadu 600040',
      age: 27,
      gender: 'Female',
      education: [
        {
          college: 'PSG College of Technology, Coimbatore',
          degree: 'MCA',
          year: 2019,
          marks: '8.7 CGPA',
        },
        {
          college: 'Madras University',
          degree: 'BCA',
          year: 2016,
          marks: '8.5 CGPA',
        },
      ],
      experience: [
        {
          company: 'Zoho Corporation',
          role: 'Full Stack Developer',
          years: 2,
        },
        { company: 'TCS', role: 'Junior Developer', years: 1 },
      ],
      currentStatus: 'Employed',
      availabilityToJoin: '1 month notice',
      skills: [
        'JavaScript',
        'React',
        'Node.js',
        'MongoDB',
        'Express',
        'Docker',
      ],
    },
    {
      id: 3,
      name: 'Neha Kapoor',
      title: 'Senior Software Engineer',
      avatar: 'https://example.com/neha.jpg',
      phone: '+91 76543 21098',
      email: 'neha.kapoor@example.com',
      address: '78, Koramangala, Bangalore, Karnataka 560034',
      age: 32,
      gender: 'Female',
      education: [
        {
          college: 'PES Institute of Technology, Bangalore',
          degree: 'B.E. Computer Science',
          year: 2013,
          marks: '8.9 CGPA',
        },
      ],
      experience: [
        {
          company: 'Microsoft India',
          role: 'Senior Software Engineer',
          years: 4,
        },
        { company: 'Wipro', role: 'Software Engineer', years: 3 },
      ],
      currentStatus: 'Employed',
      availabilityToJoin: '3 months notice',
      skills: ['C++', 'Java', 'Python', 'Azure', 'Kubernetes', 'Microservices'],
    },
  ];

  const profile = profiles.find((p) => p.id === parseInt(profileId, 10));

  const breadcrumbItems = [
    { label: 'Home', href: '/customer/requirements' },
    { label: 'Requirements', href: '/customer/requirements' },
    { label: 'Profile Submitted', href: '/customer/profile' },
    {
      label: profile ? profile.name : 'Profile',
      href: `/customer/profile/${profileId}`,
    },
  ];

  if (!profile) {
    return (
      <div className="text-center text-gray-500">
        No profile data available.
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Profile Submitted
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />

      <div className="bg-white w-full">
        {/* Personal Details Section */}
        <Section title="Personal Details" className="mt-3">
          {/* ... personal details content ... */}
        </Section>

        {/* Skills Section */}
        <Section title="Skills">
          <div className="flex flex-wrap gap-2">
            {profile.skills && profile.skills.length > 0 ? (
              profile.skills.map((skill) => (
                <SkillBadge
                  key={generateKey('skill', skill, profile.id)}
                  skill={skill}
                />
              ))
            ) : (
              <div className="text-sm text-gray-600">No skills listed.</div>
            )}
          </div>
        </Section>

        {/* Experience Section */}
        <Section title="Experience Details">
          <div className="grid grid-cols-1 gap-2">
            {profile.experience && profile.experience.length > 0 ? (
              profile.experience.map((exp) => (
                <ExperienceItem
                  key={generateKey(
                    'exp',
                    `${exp.company}-${exp.role}`,
                    profile.id
                  )}
                  experience={exp}
                />
              ))
            ) : (
              <div className="text-sm text-gray-600">
                No experience details available.
              </div>
            )}
          </div>
        </Section>

        {/* Education Section */}
        <Section title="Educational Details">
          <div className="grid grid-cols-1 gap-2">
            {profile.education && profile.education.length > 0 ? (
              profile.education.map((edu) => (
                <EducationItem
                  key={generateKey(
                    'edu',
                    `${edu.college}-${edu.degree}-${edu.year}`,
                    profile.id
                  )}
                  education={edu}
                />
              ))
            ) : (
              <div className="text-sm text-gray-600">
                No educational details available.
              </div>
            )}
          </div>
        </Section>
      </div>
    </>
  );
};

export default ProfileView;
