import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogCancel,
} from 'src/components/ui/alert-dialog';

export function Alert({
  isOpen,
  onClose,
  title,
  description,
  actionText, // Default action text
  cancelText, // Custom cancel button text
  continueText, // Custom continue button text
  onCancel, // Function to call on cancel
  onContinue, // Function to call on continue
}) {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {cancelText && onCancel && (
            <AlertDialogCancel
              onClick={() => {
                onCancel();
                onClose();
              }}
            >
              {cancelText}
            </AlertDialogCancel>
          )}
          {continueText && onContinue && (
            <AlertDialogAction
              onClick={() => {
                onContinue();
                onClose();
              }}
            >
              {continueText}
            </AlertDialogAction>
          )}
          {!cancelText && !continueText && (
            <AlertDialogAction onClick={onClose}>
              {actionText}
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
