import api from '../axios.config';
import { API_ENDPOINTS, createEndpoint } from './endpoints';

export const RequirementService = {
  getReferenceData: async (refData) => {
    const response = await api.post(
      API_ENDPOINTS.REQUIREMENT.GETREFERENCEDATA,
      refData
    );
    return response;
  },
  createRequirement: async (data) => {
    const response = await api.post(
      API_ENDPOINTS.REQUIREMENT.CREATEREQUIREMENT,
      data
    );
    return response;
  },
  getSelectionProcess: async (reqId) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.REQUIREMENT.GETSELECTIONPROCESS,
      {
        id: reqId,
      }
    );

    const response = await api.get(endpoint);
    return response;
  },
  saveSelectionProcess: async (data) => {
    const response = await api.post(
      API_ENDPOINTS.REQUIREMENT.SAVESELECTIONPROCESS,
      data
    );
    return response;
  },
  getRequirementPreviewData: async (reqId) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.REQUIREMENT.GETREQUIREMENTPREVIEWDATA,
      {
        id: reqId,
      }
    );

    const response = await api.get(endpoint);
    return response;
  },
  getRequirementByStatus: async ({ id, status }) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.REQUIREMENT.GETREQUIREMENTSBYSTATUS,
      {
        id,
        status,
      }
    );

    const response = await api.get(endpoint);
    return response;
  },
  submitRequirement: async (id) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.REQUIREMENT.SUBMITREQUIREMENT,
      {
        id,
      }
    );

    const response = await api.post(endpoint);
    return response;
  },
  fetchRequirementDetails: async (id) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.REQUIREMENT.FETCHREQUIREMENTDETAILS,
      {
        id,
      }
    );

    const response = await api.get(endpoint);
    return response;
  },
  updateRequirement: async (data, id) => {
    const endpoint = createEndpoint(
      API_ENDPOINTS.REQUIREMENT.UPDATEREQUIREMENT,
      {
        id,
      }
    );

    const response = await api.put(endpoint, data);

    return response;
  },
};
