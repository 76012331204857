import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Import your page components
import CustomerLayout from '../../layouts/MainLayout/CustomerLayout';
import Login from '../../pages/Login/Login';
import Signup from '../../pages/signup/Signup';
import Requirements from '../../pages/Customer/Requirements/requirements';
import NewRequirement from '../../pages/Customer/NewRequirement/newRequirement';
import SelectionProcess from '../../pages/Customer/NewRequirement/selectionProcess';
import RequirementPreview from '../../pages/Customer/NewRequirement/requirementPreview';
import ProfileListing from '../../pages/Customer/Profile/profileListing';
import InvoiceList from '../../pages/Customer/Invoice/invoiceListing';
import InvoiceDetails from '../../pages/Customer/InvoiceDetails/invoiceDetails';
import ProfileView from '../../pages/Customer/Profile/ProfileView';
import BlogListing from '../../pages/Customer/IndustryWatch/blogListing';
import Dashboard from '../../pages/Customer/Dashboard/dashboard';
import Reports from '../../pages/Customer/Reports/reports';

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      {/* {user && user.role === "customer" ? ( */}
      <Route path="/" element={<CustomerLayout />}>
        <Route path="/customer/dashboard" element={<Dashboard />} />
        <Route path="/customer/requirements" element={<Requirements />} />
        <Route
          path="/customer/requirements/basic/:reqId?"
          element={<NewRequirement />}
        />
        <Route
          path="/customer/requirements/selection/:reqId"
          element={<SelectionProcess />}
        />
        <Route
          path="/customer/requirements/preview/:reqId"
          element={<RequirementPreview />}
        />
        <Route path="/customer/profile" element={<ProfileListing />} />
        <Route path="/customer/profile/:profileId" element={<ProfileView />} />
        <Route path="/customer/invoice" element={<InvoiceList />} />
        <Route
          path="/customer/invoice/:invoiceId"
          element={<InvoiceDetails />}
        />
        <Route path="/customer/industry-watch" element={<BlogListing />} />
        <Route path="/customer/reports" element={<Reports />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
