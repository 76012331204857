import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import FilterButtons from 'src/components/common/FilterButton';
import { RequirementService } from 'src/services/api/RequirementService';
import { ContentContainer } from './contentContainer';

const filters = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Drafts', value: 'DRAFT' },
  { label: 'Archived', value: 'ARCHIVED' },
];

const breadcrumbItems = [
  { label: 'Home', href: '/customer/dashboard' },
  { label: 'Requirements', href: '/customer/requirements' },
];

// Separate RequirementsList component
const RequirementsList = ({ isLoading, error, requirements }) => {
  if (isLoading) {
    return (
      <div className="text-center py-8 text-gray-500">
        Loading requirements...
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500 p-4">Error: {error}</p>;
  }

  if (requirements.length === 0) {
    return (
      <div className="text-left py-8 text-gray-500">No requirements found</div>
    );
  }

  return (
    <div className="space-y-3">
      {requirements.map((item) => (
        <ContentContainer
          key={item.id}
          requirementId={item.id}
          title={item.title}
          date={item.date}
          description={item.description}
          links={item.links}
          businessUnit={item.businessUnit}
        />
      ))}
    </div>
  );
};

function Requirements() {
  const navigate = useNavigate();
  const [requirements, setRequirements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('ACTIVE');

  useEffect(() => {
    const fetchRequirements = async () => {
      try {
        setIsLoading(true);
        const response = await RequirementService.getRequirementByStatus({
          id: 1, // organization id or user id
          status: currentFilter,
        });

        if (response.success) {
          const transformedRequirements = response?.data?.requirements?.map(
            (req) => ({
              id: req.id,
              title: req.title,
              date: new Date(req.created_at).toLocaleDateString(),
              businessUnit: req?.business_unit,
              description: '',
              links: [
                `Required: ${0}`,
                `Offers Made: ${0}`,
                `Profiles Submitted: ${0}`,
                `Closing Date: ${new Date(
                  req.created_at
                ).toLocaleDateString()}`,
              ],
            })
          );
          setRequirements(transformedRequirements);
        } else {
          throw new Error(response.error || 'Failed to fetch requirements');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching requirements:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequirements();
  }, [currentFilter]);

  const handleClick = () => {
    navigate('/customer/requirements/basic');
  };

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <>
      {/* Stable header section */}
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Requirements
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="flex justify-between items-center pb-4 md:pb-6 lg:pb-0">
        <FilterButtons
          filters={filters}
          defaultFilter={currentFilter}
          onFilterChange={handleFilterChange}
        />
        <div>
          <Button className="btn-ahedge-outline" onClick={handleClick}>
            Create New
          </Button>
        </div>
      </div>

      {/* Dynamic content section */}
      <div className="items-center justify-center rounded-lg shadow-sm min-h-[200px]">
        <RequirementsList
          isLoading={isLoading}
          error={error}
          requirements={requirements}
        />
      </div>
    </>
  );
}

export default Requirements;
