import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import FilterButtons from 'src/components/common/FilterButton';
import { Briefcase, GraduationCap, Code } from 'lucide-react';

// Sample data for profiles
const initialProfiles = [
  { id: 1, name: 'Ananya Singh', experience: '5 years', qualification: 'Msc' },
  {
    id: 2,
    name: 'Priya Krishnan',
    experience: '3 years',
    qualification: 'MCA',
  },
  { id: 3, name: 'Neha Kapoor', experience: '7 years', qualification: 'BE' },
  // Add more profiles as needed
];

const ProfileListing = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [profiles, setProfiles] = useState(initialProfiles);
  const requirementId = 'REQ12345'; // Example Requirement ID

  const breadcrumbItems = [
    { label: 'Home', href: '/customer/requirements' },
    { label: 'Requirements', href: '/customer/requirements' },
    { label: 'Profile Submitted', href: '/customer/profile' },
  ];

  const filters = [
    { label: 'New', value: 'new' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Accepted', value: 'accepted' },
  ];

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filteredProfiles = initialProfiles.filter(
      (profile) =>
        profile.name.toLowerCase().includes(term) ||
        profile.experience.toLowerCase().includes(term) ||
        profile.qualification.toLowerCase().includes(term)
    );
    setProfiles(filteredProfiles);
  };

  const handleFilterChange = (filter) => {
    console.log(`Filter changed to: ${filter}`);
    // Implement your filter logic here
  };

  const handleDetails = (id) => {
    console.log(`View details for profile ${id}`);
    navigate(`/customer/profile/${id}`);
    // Implement navigation to detail page or modal open
  };
  // const handleClick = () => {
  //   navigate('/customer/requirements/basic');
  // };

  return (
    <>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Profile Submitted
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="p-4 rounded-t-lg lightBlueBg">
        <div className="flex justify-between items-center">
          <div className="text-left">
            <h1 className="text-xl font-bold">{requirementId}</h1>
          </div>
          <div className="text-right">
            <p className="text-sm text-muted-foreground">
              {profiles.length} Profiles
            </p>
          </div>
        </div>
      </div>
      <div className="pl-0 pr-4 text-left py-4 md:py-6 lg:py-0">
        <FilterButtons
          filters={filters}
          defaultFilter="new"
          onFilterChange={handleFilterChange}
        />
      </div>
      <div className="flex-grow overflow-hidden">
        <div className="h-full flex flex-col">
          <Input
            type="text"
            placeholder="Search profiles..."
            value={searchTerm}
            onChange={handleSearch}
            className="mb-4"
          />

          <div className="overflow-y-auto flex-grow">
            {profiles.map((profile) => (
              <div
                key={profile.id}
                className="border p-4 rounded-lg shadow-sm mb-4 last:mb-0"
              >
                <div className="flex flex-col items-start w-full">
                  <Button
                    variant="link"
                    className="p-0 h-auto no-underline hover:no-underline text-sm font-semibold mb-2 text-left w-full justify-start"
                    onClick={() => handleDetails(profile.id)}
                  >
                    {profile.name}
                  </Button>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 text-sm text-gray-600 w-full">
                    <div className="flex items-center">
                      <Briefcase className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span>{profile.experience}</span>
                    </div>
                    <div className="flex items-center">
                      <GraduationCap className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span>{profile.qualification}</span>
                    </div>
                    <div className="flex items-center">
                      <Code className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span>Javascript, ReactJS, Python</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileListing;
