import React from 'react';
import { useFieldArray, useWatch, Controller } from 'react-hook-form';
import { cn } from 'src/lib/utils';

import { Label } from 'src/components/ui/label';
import { Button } from 'src/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';

export const DynamicFieldArray = ({
  control,
  name,
  options = [],
  label,
  isOptional = false,
}) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name,
  });

  const values = useWatch({
    control,
    name,
    defaultValue: [],
  });

  const getSelectedValues = () =>
    Array.isArray(values)
      ? values.map((item) => item?.value).filter(Boolean)
      : [];

  const isLastFieldEmpty = (currentValues) =>
    Array.isArray(currentValues) && currentValues.length > 0
      ? !currentValues[currentValues.length - 1]?.value
      : false;

  const filteredOptions = (selectedValues, currentValue) =>
    Array.isArray(options)
      ? options.filter(
          (option) =>
            option?.value &&
            (!selectedValues.includes(option.value) ||
              option.value === currentValue)
        )
      : [];

  // Initialize with one empty field if no fields exist
  React.useEffect(() => {
    if (fields.length === 0) {
      append({ value: '', isMandatory: false });
    }
  }, [append, fields.length]);

  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      {!isOptional && <span className="text-red-500 ml-1">*</span>}

      {fields.map((field, index) => {
        const selectedValues = getSelectedValues();
        const availableOptions = filteredOptions(
          selectedValues,
          values?.[index]?.value
        );

        return (
          <div key={field.id} className="flex items-center space-x-2 mb-4 mt-1">
            <div className="w-1/2">
              <Controller
                name={`${name}.${index}.value`}
                control={control}
                rules={{
                  required: !isOptional && values?.[index]?.isMandatory,
                }}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    onValueChange={(newValue) => {
                      onChange(newValue);
                      update(index, {
                        ...values?.[index],
                        value: newValue,
                      });
                    }}
                    value={value || ''}
                  >
                    <SelectTrigger
                      className={cn('w-full', error && 'border-red-500')}
                    >
                      <SelectValue placeholder={`Select ${label}`}>
                        {value
                          ? options.find((opt) => opt.value === value)?.label
                          : ''}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {availableOptions.map((option) => (
                        <SelectItem
                          key={`${name}-${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            <Button
              type="button"
              className={cn(
                'w-28 px-2 whitespace-nowrap overflow-hidden text-ellipsis',
                values?.[index]?.isMandatory
                  ? 'border-red-500 text-red-500 hover:bg-red-50'
                  : 'border-gray-300'
              )}
              variant="outline"
              onClick={() =>
                update(index, {
                  ...values?.[index],
                  isMandatory: !values?.[index]?.isMandatory,
                })
              }
            >
              <span className="block w-full text-center">
                {values?.[index]?.isMandatory ? 'Mandatory' : 'Optional'}
              </span>
            </Button>

            {fields.length > 1 && (
              <Button
                type="button"
                variant="outline"
                onClick={() => remove(index)}
              >
                -
              </Button>
            )}

            {index === fields.length - 1 && (
              <Button
                type="button"
                variant="outline"
                onClick={() => append({ value: '', isMandatory: false })}
                disabled={isLastFieldEmpty(values)}
              >
                +
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};
