export const defaultFormValues = {
  jobTitle: '',
  businessUnit: '',
  closingDate: null,
  careerLevel: '',
  minExperience: '',
  description: '',
  additionalInstructions: '',
  salary: '',
  employmentType: 'FULL_TIME',
  locations: [{ location: '', count: '' }],
  minEducation: '',
  fieldsOfStudy: [{ value: '', isMandatory: false }],
  professionalCertifications: [{ value: '', isMandatory: false }],
  skills: [{ value: '', isMandatory: false }],
  regulatoryKnowledge: [{ value: '', isMandatory: false }],
};
