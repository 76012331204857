import api from '../axios.config';
import { API_ENDPOINTS, createEndpoint } from './endpoints';

export const UserService = {
  verifyUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.VERIFY, userData);
    return response.data;
  },

  registerUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.REGISTER, userData);
    return response;
  },

  loginUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.LOGIN, userData);
    return response;
  },

  activateAccount: async (activateToken) => {
    const endpoint = createEndpoint(API_ENDPOINTS.USER.ACTIVATE, {
      token: activateToken,
    });
    const response = await api.get(endpoint);

    return response;
  },

  sendOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.SENDOTP, data);
    return response;
  },

  verifyOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.VERIFYOTP, data);
    return response;
  },
  resendOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.RESENDOTP, data);
    return response;
  },
};
