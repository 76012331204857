import React from 'react';
import { Controller } from 'react-hook-form';
import { cn } from 'src/lib/utils';

import { Label } from 'src/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { DynamicFieldArray } from './DynamicFieldArray';

const FormSection = ({ title, children }) => (
  <div className="mt-8 relative">
    <div className="absolute -top-3 left-4 px-2 bg-background">
      <h4 className="font-bold pageTitle">{title}</h4>
    </div>
    <div className="rounded-lg border shadow-sm p-4 lightBlueBg pt-6 border-gray-300 space-y-3">
      {children}
    </div>
  </div>
);

export const EligibilityRequirements = ({ form, masterData = {} }) => {
  const {
    control,
    formState: { errors },
  } = form;

  // Destructure masterData with default empty arrays
  const {
    workExperiences = [],
    educations = [],
    fieldOptions = [],
  } = masterData;

  return (
    <FormSection title="Eligibility">
      <div className="space-y-3">
        <div>
          <Label htmlFor="minExperience">Work Experience</Label>
          <span className="text-red-500 ml-1">*</span>
          <div className="mt-1">
            <Controller
              name="minExperience"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  value={field.value?.toString()} // Convert to string if it exists
                >
                  <SelectTrigger
                    id="minExperience"
                    className={cn(
                      'w-full',
                      errors.minExperience && 'border-red-500'
                    )}
                  >
                    <SelectValue placeholder="Select Work Experience" />
                  </SelectTrigger>
                  <SelectContent>
                    {workExperiences.map((experience) => (
                      <SelectItem
                        key={experience.value}
                        value={experience.value}
                      >
                        {experience.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
        </div>

        <div>
          <Label htmlFor="minEducation">Education</Label>
          <span className="text-red-500 ml-1">*</span>
          <div className="mt-1">
            <Controller
              name="minEducation"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    id="minEducation"
                    className={cn(
                      'w-full',
                      errors.minEducation && 'border-red-500'
                    )}
                  >
                    <SelectValue placeholder="Select Education" />
                  </SelectTrigger>
                  <SelectContent>
                    {educations.map((education) => (
                      <SelectItem key={education.value} value={education.value}>
                        {education.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
        </div>

        <DynamicFieldArray
          control={control}
          name="fieldsOfStudy"
          options={fieldOptions}
          label="Fields of Study"
        />
      </div>
    </FormSection>
  );
};
