import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import logoImg from 'src/assets/images/ahedge_logo.svg';
import { navigation } from 'src/config/navigation';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar() {
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState({ Staffing: true });

  const toggleSubmenu = (itemName) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemName]: !prev[itemName],
    }));
  };

  const isUrlActive = (urlParam) => {
    const urls = typeof urlParam === 'string' ? [urlParam] : urlParam;
    return urls.some((url) => location.pathname.startsWith(url));
  };

  const isSubmenuItemActive = (item) =>
    item.subItems && item.subItems.some((subItem) => isUrlActive(subItem.href));

  const getMenuItemClass = (item, isMainMenuActive, isSubmenuActive) => {
    if (isMainMenuActive && !item.subItems) return 'bg-menuhover text-primary';
    if (isSubmenuActive) return 'text-primary';
    return 'text-muted-foreground';
  };

  const renderMenuItem = (item) => {
    const isMainMenuActive = isUrlActive(item.href);
    const isSubmenuActive = isSubmenuItemActive(item);
    const isExpanded = expandedItems[item.name];
    const baseMenuClass = getMenuItemClass(
      item,
      isMainMenuActive,
      isSubmenuActive
    );

    return (
      <div key={item.name}>
        <Link
          to={Array.isArray(item.href) ? item.href[0] : item.href}
          className={classNames(
            baseMenuClass,
            'flex items-center justify-between gap-3 rounded-lg px-3 py-3 transition-all hover:text-primary',
            item.comingSoon
              ? 'cursor-default pointer-events-none opacity-50'
              : ''
          )}
          onClick={(e) => {
            if (item.subItems || item.comingSoon) {
              e.preventDefault();
              if (item.subItems) {
                toggleSubmenu(item.name);
              }
            }
          }}
        >
          <div className="flex items-center gap-3">
            <item.icon className="h-4 w-4" />
            {item.name}
          </div>
          {item.comingSoon && (
            <span className="text-[10px] bg-yellow-200 text-yellow-800 px-1 py-0.5 rounded leading-none">
              Coming Soon
            </span>
          )}
          {item.subItems &&
            (isExpanded ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            ))}
        </Link>
        {item.subItems && isExpanded && (
          <div className="ml-6 mt-2 space-y-1">
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={
                  Array.isArray(subItem.href) ? subItem.href[0] : subItem.href
                }
                className={classNames(
                  isUrlActive(subItem.href)
                    ? 'bg-menuhover text-primary'
                    : 'text-muted-foreground hover:text-primary',
                  'flex items-center gap-2 rounded-md px-3 py-2 text-sm'
                )}
              >
                <subItem.icon className="h-4 w-4" />
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="hidden bg-muted/40 md:block md:min-w-[250px]">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6 headerBg">
          <Link
            to="/customer/requirements"
            className="flex items-center gap-2 font-semibold"
          >
            <img
              src={logoImg}
              alt="Logo"
              className="h-full w-full object-none"
            />
          </Link>
        </div>
        <div className="flex-1 overflow-auto">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4 mt-2">
            {navigation.map(renderMenuItem)}
          </nav>
        </div>
      </div>
    </div>
  );
}
