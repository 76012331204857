import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Users, TrendingUp, Hourglass, IndianRupee } from 'lucide-react';

const data = [
  { name: 'Jan', total: 1000 },
  { name: 'Feb', total: 1900 },
  { name: 'Mar', total: 1400 },
  { name: 'Apr', total: 1700 },
  { name: 'May', total: 1200 },
  { name: 'Jun', total: 1500 },
  { name: 'Jul', total: 800 },
  { name: 'Aug', total: 1300 },
  { name: 'Sep', total: 700 },
  { name: 'Oct', total: 1800 },
  { name: 'Nov', total: 2000 },
  { name: 'Dec', total: 1100 },
];

const recentRequirements = [
  {
    id: 'req-001',
    position: 'Risk Analyst',
    department: 'Risk Management',
    location: 'Mumbai',
    experience: '3-5 years',
  },
  {
    id: 'req-002',
    position: 'Compliance Officer',
    department: 'Legal & Compliance',
    location: 'Delhi',
    experience: '5-7 years',
  },
  {
    id: 'req-003',
    position: 'Financial Controller',
    department: 'Finance',
    location: 'Bangalore',
    experience: '7-10 years',
  },
  {
    id: 'req-004',
    position: 'AML Specialist',
    department: 'Compliance',
    location: 'Hyderabad',
    experience: '4-6 years',
  },
  {
    id: 'req-005',
    position: 'Credit Risk Manager',
    department: 'Risk Management',
    location: 'Pune',
    experience: '6-8 years',
  },
];

const Dashboard = () => (
  <div className="space-y-4">
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      <Card className="bg-[#F0F8FF]">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Billing</CardTitle>
          <IndianRupee className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent className="text-left">
          <div className="text-2xl font-bold">₹45,231.89</div>
          <p className="text-xs text-muted-foreground">
            +20.1% from last month
          </p>
        </CardContent>
      </Card>
      <Card className="bg-[#F0F8FF]">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Retention Rate (1 Year)
          </CardTitle>
          <Users className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent className="text-left">
          <div className="text-2xl font-bold">92%</div>
          <p className="text-xs text-muted-foreground">
            +5% from previous year
          </p>
        </CardContent>
      </Card>
      <Card className="bg-[#F0F8FF]">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Avg Time to Fill Positions
          </CardTitle>
          <Hourglass className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent className="text-left">
          <div className="text-2xl font-bold">45 days</div>
          <p className="text-xs text-muted-foreground">
            -5 days from last quarter
          </p>
        </CardContent>
      </Card>
      <Card className="bg-[#F0F8FF]">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Productivity Index
          </CardTitle>
          <TrendingUp className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent className="text-left">
          <div className="text-2xl font-bold">94%</div>
          <p className="text-xs text-muted-foreground">
            Compared to company average
          </p>
        </CardContent>
      </Card>
    </div>
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
      <Card className="col-span-4">
        <CardHeader>
          <CardTitle>Overview</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={data}>
              <XAxis
                dataKey="name"
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `₹${value}`}
              />
              <Bar
                dataKey="total"
                fill="currentColor"
                radius={[4, 4, 0, 0]}
                className="fill-primary"
              />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
      <Card className="col-span-3">
        <CardHeader>
          <CardTitle>Recent Requirements</CardTitle>
          <p className="text-sm text-muted-foreground">
            You have 25 new job requirements this month.
          </p>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {recentRequirements.map((req) => (
              <div key={req.id} className="flex flex-col space-y-1">
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">{req.position}</p>
                  <span className="text-sm text-muted-foreground">
                    {req.location}
                  </span>
                </div>
                <div className="flex justify-between items-center text-sm text-muted-foreground">
                  <span>{req.department}</span>
                  <span>{req.experience}</span>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
);

export default Dashboard;
