import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserService } from 'src/services/api/UserService';
import { useAuth } from './useAuth';

export const useAuthActions = () => {
  const navigate = useNavigate();
  const { login: setAuthUser, logout: clearAuthUser } = useAuth();

  const logout = () => {
    clearAuthUser();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    console.log('logout invoked');

    navigate('/login');
  };

  const login = async (credentials) => {
    try {
      // Clear any existing auth state before attempting new login
      logout();

      // Make API call using UserService
      const response = await UserService.loginUser(credentials);

      // Extract data from response
      const { data } = response;
      const { tokens, user_info: userInfo } = data;

      if (tokens?.access_token) {
        // Store tokens
        localStorage.setItem('token', tokens.access_token);
        if (tokens.refresh_token) {
          localStorage.setItem('refreshToken', tokens.refresh_token);
        }

        // Format user data
        const userData = {
          id: userInfo.sub,
          cid: userInfo.cid,
          email: userInfo.email,
          name: userInfo.name,
          givenName: userInfo.given_name,
          familyName: userInfo.family_name,
          username: userInfo.preferred_username,
          emailVerified: userInfo.email_verified,
          dbSyncStatus: userInfo.dbSyncStatus,
        };

        // Set user in auth context
        setAuthUser(userData);

        // Store user data
        localStorage.setItem('user', JSON.stringify(userData));

        // Log successful login in development
        if (process.env.NODE_ENV === 'development') {
          console.log('User logged in:', userData);
        }

        // Navigate to submit resume
        navigate('/dashboard');
      }

      return data;
    } catch (error) {
      console.error('Login failed:', error);

      // Handle specific API error responses
      if (error.response) {
        switch (error.response.status) {
          case 401:
            throw new Error('Invalid email or password');
          case 403:
            throw new Error('Account is locked. Please contact support');
          case 404:
            throw new Error('Account not found');
          case 422:
            throw new Error('Invalid login credentials');
          case 429:
            throw new Error('Too many login attempts. Please try again later');
          default:
            throw new Error(
              error.response.data?.message || 'An error occurred during login'
            );
        }
      } else if (error.request) {
        // Network error
        throw new Error(
          'Unable to connect to the server. Please check your internet connection'
        );
      } else {
        throw new Error('An unexpected error occurred');
      }
    }
  };

  const refreshAuthToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await axios.post('/api/auth/refresh-token', {
        refreshToken,
      });

      const { tokens } = response.data;
      const newToken = tokens.access_token;
      const newRefreshToken = tokens.refresh_token;

      // Update tokens
      localStorage.setItem('token', newToken);
      if (newRefreshToken) {
        localStorage.setItem('refreshToken', newRefreshToken);
      }

      return newToken;
    } catch (error) {
      console.error('Token refresh failed:', error);
      // If refresh fails, log out the user
      logout();
      throw new Error('Session expired. Please login again');
    }
  };

  // Initialize axios interceptor for token refresh
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If error is 401 and we haven't tried refreshing the token yet
      if (error.response?.status === 401 && !originalRequest.isRetry) {
        originalRequest.isRetry = true;

        try {
          // Try to refresh the token
          const newToken = await refreshAuthToken();

          // Update the authorization header
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // Retry the original request
          return axios(originalRequest);
        } catch (refreshError) {
          // If refresh fails, throw the error
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  // Set up axios defaults
  axios.defaults.baseURL =
    process.env.REACT_APP_API_URL || 'http://localhost:3000';

  // Create a new interceptor instance to avoid mutating the config parameter
  const requestInterceptor = (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return config;
  };

  // Add the request interceptor
  axios.interceptors.request.use(requestInterceptor);

  return { login, logout, refreshAuthToken };
};
