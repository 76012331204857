import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from 'src/components/ui/sheet';
import { CircleUser, Menu } from 'lucide-react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { navigation } from 'src/config/navigation';
import logoImg from 'src/assets/images/ahedge_logo.svg';
import { useAuth } from '../../hooks/useAuth';

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const userNavigation = [
  { id: 'profile', name: 'Profile', href: '#' },
  { id: 'settings', name: 'Settings', href: '#' },
  { id: 'support', name: 'Support', href: '#' },
  { id: 'signout', name: 'Sign out', action: 'logout' },
];

const Header = () => {
  const [expandedItems, setExpandedItems] = useState({ Staffing: true });
  const location = useLocation();
  const navigate = useNavigate();
  const { user, login, logout, isLoading } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isUrlActive = (urlPatterns) => {
    const patterns = Array.isArray(urlPatterns) ? urlPatterns : [urlPatterns];
    return patterns.some((url) => location.pathname.startsWith(url));
  };

  const isSubmenuItemActive = (item) =>
    item.subItems?.some((subItem) => isUrlActive(subItem.href));

  const toggleSubmenu = (itemName) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemName]: !prev[itemName],
    }));
  };

  const renderMenuItem = (item) => {
    const isMainMenuActive = isUrlActive(item.href);
    const isSubmenuActive = isSubmenuItemActive(item);
    const isExpanded = expandedItems[item.name];

    const menuItemClasses = classNames(
      {
        'bg-menuhover text-primary': isMainMenuActive && !item.subItems,
        'text-primary': isSubmenuActive,
        'text-muted-foreground': !isMainMenuActive && !isSubmenuActive,
      },
      'flex items-center justify-between gap-3 rounded-lg px-3 py-3 transition-all hover:text-primary',
      {
        'cursor-default pointer-events-none opacity-50': item.comingSoon,
      }
    );

    return (
      <div key={item.name}>
        <Link
          to={Array.isArray(item.href) ? item.href[0] : item.href}
          className={menuItemClasses}
          onClick={(e) => {
            if (item.subItems || item.comingSoon) {
              e.preventDefault();
              if (item.subItems) {
                toggleSubmenu(item.name);
              }
            }
          }}
        >
          <div className="flex items-center gap-3">
            <item.icon className="h-4 w-4" />
            {item.name}
          </div>
          {item.comingSoon && (
            <span className="text-[10px] bg-yellow-200 text-yellow-800 px-1 py-0.5 rounded leading-none">
              Coming Soon
            </span>
          )}
          {item.subItems &&
            (isExpanded ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            ))}
        </Link>
        {item.subItems && isExpanded && (
          <div className="ml-6 mt-2 space-y-1">
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={
                  Array.isArray(subItem.href) ? subItem.href[0] : subItem.href
                }
                className={classNames(
                  isUrlActive(subItem.href)
                    ? 'bg-menuhover text-primary'
                    : 'text-muted-foreground hover:text-primary',
                  'flex items-center gap-2 rounded-md px-3 py-2 text-sm'
                )}
              >
                <subItem.icon className="h-4 w-4" />
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <header className="flex h-14 items-center gap-4 border-b headerBg px-4 lg:h-[60px] lg:px-6">
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-[300px] sm:w-[400px]">
          <nav className="grid gap-2 text-lg font-medium">
            <Link
              to="/customer/requirements"
              className="flex items-center gap-2 font-semibold mb-4"
            >
              <img src={logoImg} alt="Company logo" className="h-8 w-auto" />
            </Link>
            {navigation.map(renderMenuItem)}
          </nav>
        </SheetContent>
      </Sheet>

      <div className="w-full flex-1" />

      {user ? (
        <>
          <span className="text-white">{user.username}</span>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">{user.username}</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {userNavigation.map((item) => (
                <DropdownMenuItem
                  key={item.id}
                  onClick={item.action === 'logout' ? handleLogout : undefined}
                  asChild={!item.action}
                >
                  {item.action === 'logout' ? (
                    <button type="button" className="w-full text-left">
                      {item.name}
                    </button>
                  ) : (
                    <Link to={item.href}>{item.name}</Link>
                  )}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      ) : (
        <Button onClick={login}>Sign In</Button>
      )}
    </header>
  );
};

export default Header;
