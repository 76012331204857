import React from 'react';
import { Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { cn } from 'src/lib/utils';
import { Calendar as CalendarIcon } from 'lucide-react';

import { Label } from 'src/components/ui/label';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';

export const ClosingDate = ({ form }) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <div className="mt-8 relative">
      <div>
        <Label htmlFor="closingDate">Requirement Closing Date</Label>
        <span className="text-red-500 ml-1">*</span>
        <div className="mt-1">
          <Controller
            name="closingDate"
            control={control}
            rules={{ required: 'Closing date is required' }}
            render={({ field: { onChange, value } }) => (
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="closingDate"
                    variant="outline"
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !value && 'text-muted-foreground',
                      errors.closingDate && 'border-red-500'
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {value ? (
                      format(new Date(value), 'PPP')
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={value ? new Date(value) : undefined}
                    onSelect={(date) => {
                      if (date) {
                        onChange(date);
                      }
                    }}
                    disabled={(date) =>
                      date < new Date() || date < new Date('1900-01-01')
                    }
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            )}
          />
        </div>
      </div>
    </div>
  );
};
