import React from 'react';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'src/components/ui/select';

export function DataTable({
  columns,
  data,
  showSearch = true,
  showPagination = true,
  itemsPerPageOptions = [10, 20, 30, 40, 50],
  defaultItemsPerPage = 10,
  rowKeyField = 'id', // New prop to specify which field to use as row key
}) {
  const [sorting, setSorting] = React.useState([]);
  const [filtering, setFiltering] = React.useState('');
  const [itemsPerPage, setItemsPerPage] = React.useState(defaultItemsPerPage);

  // Function to generate a stable key for a row
  const getRowKey = (row) => {
    // If the specified rowKeyField exists in the row data, use it
    if (row[rowKeyField] !== undefined) {
      return row[rowKeyField];
    }
    // Otherwise, create a composite key from all values in the row
    return Object.values(row)
      .map((value) => String(value))
      .join('_');
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    globalFilterFn: 'includesString',
    state: {
      sorting,
      globalFilter: filtering,
      pagination: {
        pageSize: itemsPerPage,
      },
    },
  });

  const showPaginationControls = showPagination && data.length > itemsPerPage;

  return (
    <div>
      {showSearch && (
        <div className="flex items-center pb-4">
          <Input
            placeholder="Filter records..."
            value={filtering}
            onChange={(e) => setFiltering(e.target.value)}
            className="max-w-sm"
          />
        </div>
      )}

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHead key={column.accessorKey || column.id}>
                  {column.header}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={getRowKey(row.original)}>
                  {columns.map((column) => (
                    <TableCell key={column.accessorKey || column.id}>
                      {column.cell
                        ? column.cell({
                            row: {
                              original: row.original,
                              getValue: (key) => row.original[key],
                            },
                          })
                        : row.original[column.accessorKey]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results. (Data length: {data.length})
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {showPaginationControls && (
        <div className="flex items-center justify-between space-x-2 py-4">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">Rows per page</p>
            <Select
              value={itemsPerPage.toString()}
              onValueChange={(value) => {
                setItemsPerPage(Number(value));
                table.setPageSize(Number(value));
              }}
            >
              <SelectTrigger className="w-[70px]">
                <SelectValue placeholder={itemsPerPage} />
              </SelectTrigger>
              <SelectContent>
                {itemsPerPageOptions.map((option) => (
                  <SelectItem key={option} value={option.toString()}>
                    {option}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
