import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Button } from 'src/components/ui/button';
import { Alert } from 'src/components/common/Alert';
import { Breadcrumb } from 'src/components/common/Breadcrumb';

import { JobRequirements } from 'src/components/Requirements/JobRequirements';
import { EligibilityRequirements } from 'src/components/Requirements/EligibilityRequirements';
import { ProfessionalCertifications } from 'src/components/Requirements/ProfessionalCertifications';
import { SkillsKnowledge } from 'src/components/Requirements/SkillsKnowledge';
import { ClosingDate } from 'src/components/Requirements/ClosingDate';

import { useRequirementData } from 'src/hooks/useRequirementData';
import { useRequirementForm } from 'src/hooks/useRequirementForm';
import { defaultFormValues } from 'src/utils/requirementConstants';
import { RequirementService } from 'src/services/api/RequirementService';

const getBreadcrumbItems = (id) => [
  { label: 'Home', href: '/customer/dashboard' },
  { label: 'Requirements', href: '/customer/requirements' },
  {
    label: 'Basic Info',
    href: `/customer/requirements/basic${id ? `/${id}` : ''}`,
  },
];

const NewRequirement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { reqId } = useParams();
  const breadcrumbItems = getBreadcrumbItems(reqId);

  const [requirementData, setRequirementData] = useState(null);
  const [isLoadingRequirement, setIsLoadingRequirement] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const existingData = location.state?.requirementData;
  const { isLoading, error, masterData } = useRequirementData();

  useEffect(() => {
    const fetchRequirementData = async () => {
      if (!reqId) return;

      setIsLoadingRequirement(true);
      setFetchError(null);

      try {
        // eslint-disable-next-line prettier/prettier
        const response = await RequirementService.fetchRequirementDetails(
          reqId
        );
        const result = response.data;
        const mapFieldsOfStudy = (qualifications) => {
          const selectedQualification = qualifications.find(
            (qual) =>
              qual.qualification_type ===
              result.qualification_requirements[0]?.qualification_type
          );

          // Map fields of study if they exist
          return (
            selectedQualification?.field_of_study?.map((field) => ({
              id: field.field_of_study_id.toString(),
              field: field.field_of_study_id.toString(),
              value: field.field_of_study_id.toString(),
              isMandatory: field.is_mandatory,
            })) || []
          );
        };

        const mapCertifications = (certifications) =>
          certifications.map((cert) => ({
            id: cert.certification_id.toString(),
            field: cert.certification_id.toString(),
            value: cert.certification_id.toString(),
            isMandatory: cert.is_mandatory, // Using the boolean value from API directly
          }));

        const mapSkills = (skills) =>
          skills.map((skill) => ({
            id: skill.skill_id.toString(),
            field: skill.skill_id.toString(),
            value: skill.skill_id.toString(),
            isMandatory: skill.is_mandatory, // Using the boolean value from API directly
          }));

        // Transform API response to match form structure
        const transformedData = {
          // Form field mappings
          jobTitle: result.title,
          businessUnit: result.business_unit_id.toString(),
          careerLevel: result.career_level,
          description: result.detailed_job_description,
          additionalInstructions: result.additional_requirements,
          salary: result.minimum_salary.toString(),
          employmentType: result.employment_type,
          locations: result.locations.map((loc) => ({
            location: loc.location_id.toString(),
            count: loc.headcount.toString(), // Changed to 'count' to match the component
          })),

          minExperience: result.work_experience_id.toString(),
          minEducation:
            result.qualification_requirements[0]?.qualification_type,
          fieldsOfStudy: mapFieldsOfStudy(result.qualification_requirements),

          // Skills - match SkillsKnowledge component structure
          skills: mapSkills(result.skills),

          // Certifications - match ProfessionalCertifications component structure
          professionalCertifications: mapCertifications(result.certifications),

          // Closing Date
          closingDate: result.closing_date,
        };
        setRequirementData(transformedData);
      } catch (err) {
        console.error('Fetch error:', err);
        setFetchError(err.message);
      } finally {
        setIsLoadingRequirement(false);
      }
    };

    fetchRequirementData();
  }, [reqId]);

  // Update page title based on reqId
  // const pageTitle = reqId ? 'Edit Requirement' : 'Create New Requirement';

  const {
    form,
    isAlertOpen,
    isSubmitting,
    submitError,
    handleRoleChange,
    handleAlertClose,
    handleCancel,
    handleContinue,
    handleNextClick,
  } = useRequirementForm({
    defaultValues: requirementData || existingData || defaultFormValues,
    reqId,
    onSubmit: async (data) => {
      const requirementId = reqId || data.requirementId;
      navigate(`/customer/requirements/selection/${requirementId}`);
    },
  });
  useEffect(() => {
    if (requirementData) {
      form.reset(requirementData);
    }
  }, [requirementData, form]);
  if (isLoading || isLoadingRequirement) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="mt-4">Loading form data...</p>
      </div>
    );
  }

  if (error || fetchError) {
    return <p className="text-red-500 p-4 ">{error || fetchError}</p>;
  }
  if (!masterData) return null;

  return (
    <>
      <Alert
        isOpen={isAlertOpen}
        onClose={handleAlertClose}
        title="Confirm Role Change"
        description="Changing the role will affect other fields. Do you want to continue?"
        cancelText="Cancel"
        continueText="Continue"
        onCancel={handleCancel}
        onContinue={handleContinue}
      />

      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Basic Info
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="items-center justify-center">
        <form
          onSubmit={handleNextClick}
          className="text-left w-full space-y-10"
        >
          {submitError && (
            <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              <strong className="font-bold">Error!</strong>
              <span className="block sm:inline"> {submitError}</span>
            </div>
          )}

          <JobRequirements
            form={form}
            masterData={masterData}
            onRoleChange={handleRoleChange}
          />
          <EligibilityRequirements form={form} masterData={masterData} />
          <ProfessionalCertifications form={form} masterData={masterData} />
          <SkillsKnowledge form={form} masterData={masterData} />
          <ClosingDate form={form} />

          <div className="mt-6 flex justify-end">
            <Button
              type="submit"
              disabled={isSubmitting}
              className={isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}
            >
              {isSubmitting ? 'Submitting...' : 'Next'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewRequirement;
