import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

export function Breadcrumb({ items }) {
  return (
    <nav className="py-4 md:py-0" aria-label="Breadcrumb">
      <ol className="flex flex-wrap items-center -my-2">
        {items.map((item) => (
          <li
            key={item.href || `${item.label}-${items.indexOf(item)}`}
            className="flex items-center max-w-full py-1"
          >
            {items.indexOf(item) > 0 && (
              <ChevronRight className="w-4 h-4 text-gray-400 flex-shrink-0 mx-1 md:mx-2" />
            )}
            {items.indexOf(item) === items.length - 1 ? (
              <span
                className="text-sm font-medium text-gray-500 truncate"
                title={item.label}
              >
                {item.label}
              </span>
            ) : (
              <Link
                to={item.href}
                className="text-sm font-medium text-blue-600 hover:text-blue-800 truncate"
                title={item.label}
              >
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
