import React from 'react';
import { DynamicFieldArray } from './DynamicFieldArray';

const FormSection = ({ title, children }) => (
  <div className="mt-8 relative">
    <div className="absolute -top-3 left-4 px-2 bg-background">
      <h4 className="font-bold pageTitle">{title}</h4>
    </div>
    <div className="rounded-lg border shadow-sm p-4 lightBlueBg pt-6 border-gray-300 space-y-3">
      {children}
    </div>
  </div>
);

export const ProfessionalCertifications = ({ form, masterData }) => {
  const { control } = form;

  return (
    <FormSection title="Professional Certifications">
      <DynamicFieldArray
        control={control}
        name="professionalCertifications"
        options={masterData.certificationOptions}
        label="Certifications"
        isOptional
      />
    </FormSection>
  );
};
