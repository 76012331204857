// formOptions.js

export const jobTitles = [
  { value: 'software_developer', label: 'Software Developer' },
  { value: 'ux_designer', label: 'UX Designer' },
  { value: 'project_manager', label: 'Project Manager' },
  { value: 'data_analyst', label: 'Data Analyst' },
  { value: 'devops_engineer', label: 'DevOps Engineer' },
  { value: 'product_manager', label: 'Product Manager' },
  { value: 'qa_engineer', label: 'QA Engineer' },
  { value: 'business_analyst', label: 'Business Analyst' },
  { value: 'system_administrator', label: 'System Administrator' },
  { value: 'network_engineer', label: 'Network Engineer' },
];

export const businessUnits = [
  { value: 'software_development', label: 'Software Development' },
  { value: 'design', label: 'Design' },
  { value: 'project_management', label: 'Project Management' },
  { value: 'data_analytics', label: 'Data Analytics' },
  { value: 'devops', label: 'DevOps' },
  { value: 'product_management', label: 'Product Management' },
  { value: 'quality_assurance', label: 'Quality Assurance' },
  { value: 'business_analysis', label: 'Business Analysis' },
  { value: 'system_administration', label: 'System Administration' },
  { value: 'network_engineering', label: 'Network Engineering' },
];

export const careerLevels = [
  { value: 'entry_level', label: 'Entry Level' },
  { value: 'mid_level', label: 'Mid Level' },
  { value: 'senior_level', label: 'Senior Level' },
];

export const minEducations = [
  { value: 'bachelors', label: "Bachelor's Degree" },
  { value: 'masters', label: "Master's Degree" },
  { value: 'phd', label: 'PhD' },
];

export const minExperiences = [
  { value: '0-2', label: '0 - 2 Years' },
  { value: '3-5', label: '3 - 5 Years' },
  { value: '5+', label: '5+ Years' },
];

export const fieldOptions = [
  { value: 'finance', label: 'Finance' },
  { value: 'accounting', label: 'Accounting' },
  { value: 'economics', label: 'Economics' },
  { value: 'business', label: 'Business Administration' },
  { value: 'law', label: 'Law' },
  { value: 'riskManagement', label: 'Risk Management' },
  { value: 'informationSystems', label: 'Information Systems' },
  { value: 'dataScience', label: 'Data Science/Analytics' },
];

export const certificationOptions = [
  { value: 'cfa', label: 'CFA (Chartered Financial Analyst)' },
  { value: 'frm', label: 'FRM (Financial Risk Manager)' },
  { value: 'cams', label: 'CAMS (Certified Anti-Money Laundering Specialist)' },
  { value: 'cisa', label: 'CISA (Certified Information Systems Auditor)' },
  { value: 'cpa', label: 'CPA (Certified Public Accountant)' },
  { value: 'cfcs', label: 'CFCS (Certified Financial Crime Specialist)' },
  { value: 'cia', label: 'CIA (Certified Internal Auditor)' },
  { value: 'other', label: 'Other (Specify in Job Description)' },
];

export const skillOptions = [
  { value: 'riskModeling', label: 'Risk Modeling' },
  { value: 'dataAnalysis', label: 'Data Analysis' },
  { value: 'regulatoryReporting', label: 'Regulatory Reporting' },
  { value: 'amlSystems', label: 'AML Transaction Monitoring Systems' },
  { value: 'fraudDetection', label: 'Fraud Detection Techniques' },
  { value: 'auditingTools', label: 'Auditing Tools and Techniques' },
  { value: 'sql', label: 'SQL' },
  { value: 'python', label: 'Python' },
  { value: 'r', label: 'R' },
  { value: 'tableau', label: 'Tableau or Similar BI Tools' },
];

export const regulatoryOptions = [
  { value: 'basel', label: 'Basel III/IV' },
  { value: 'kyc', label: 'KYC Procedures' },
  { value: 'aml', label: 'AML Regulations' },
  { value: 'fatca', label: 'FATCA' },
  { value: 'gdpr', label: 'GDPR' },
  { value: 'mifid', label: 'MiFID II' },
  { value: 'doddFrank', label: 'Dodd-Frank Act' },
];

export const salaryRanges = [
  { value: '0-300000', label: '₹0 - ₹3,00,000' },
  { value: '300001-600000', label: '₹3,00,001 - ₹6,00,000' },
  { value: '600001-900000', label: '₹6,00,001 - ₹9,00,000' },
  { value: '900001-1200000', label: '₹9,00,001 - ₹12,00,000' },
  { value: '1200001-1500000', label: '₹12,00,001 - ₹15,00,000' },
  { value: '1500001-2000000', label: '₹15,00,001 - ₹20,00,000' },
  { value: '2000001-2500000', label: '₹20,00,001 - ₹25,00,000' },
  { value: '2500001+', label: 'Above ₹25,00,000' },
];

export const employmentTypes = [
  { value: 'onroll', label: 'On Roll' },
  { value: 'outsourced', label: 'Outsourced' },
];
