import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Breadcrumb } from 'src/components/common/Breadcrumb';

const Reports = () => {
  const roiData = [
    { month: 'Jan', roi: 120 },
    { month: 'Feb', roi: 132 },
    { month: 'Mar', roi: 125 },
    { month: 'Apr', roi: 145 },
    { month: 'May', roi: 158 },
    { month: 'Jun', roi: 150 },
    { month: 'Jul', roi: 165 },
    { month: 'Aug', roi: 170 },
    { month: 'Sep', roi: 168 },
    { month: 'Oct', roi: 180 },
    { month: 'Nov', roi: 185 },
    { month: 'Dec', roi: 190 },
  ];

  const monthlyHires = [
    { name: 'Jan', hires: 12 },
    { name: 'Feb', hires: 19 },
    { name: 'Mar', hires: 15 },
    { name: 'Apr', hires: 22 },
    { name: 'May', hires: 18 },
    { name: 'Jun', hires: 25 },
    { name: 'Jul', hires: 20 },
    { name: 'Aug', hires: 23 },
    { name: 'Sep', hires: 17 },
    { name: 'Oct', hires: 21 },
    { name: 'Nov', hires: 26 },
    { name: 'Dec', hires: 24 },
  ];

  const timeToFill = [
    { position: 'Risk Analyst', days: 45 },
    { position: 'Compliance Officer', days: 60 },
    { position: 'Financial Controller', days: 75 },
    { position: 'AML Specialist', days: 50 },
    { position: 'Credit Risk Manager', days: 55 },
  ];

  const hiringEfficiency = [
    { month: 'Jan', efficiency: 85 },
    { month: 'Feb', efficiency: 88 },
    { month: 'Mar', efficiency: 92 },
    { month: 'Apr', efficiency: 90 },
    { month: 'May', efficiency: 95 },
    { month: 'Jun', efficiency: 93 },
    { month: 'Jul', efficiency: 91 },
    { month: 'Aug', efficiency: 94 },
    { month: 'Sep', efficiency: 89 },
    { month: 'Oct', efficiency: 92 },
    { month: 'Nov', efficiency: 96 },
    { month: 'Dec', efficiency: 93 },
  ];
  const breadcrumbItems = [
    { label: 'Home', href: '/customer/dashboard' },
    { label: 'Reports', href: '/customer/Reports' },
  ];

  return (
    <>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">Reports</h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />
      <div className="items-center justify-center ">
        <div className="grid gap-4 md:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">
                ROI from Recruitment Services Over Time
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={roiData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="roi"
                    stroke="#8884d8"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">
                Monthly Hires
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={monthlyHires}>
                  <XAxis
                    dataKey="name"
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                  />
                  <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="hires"
                    fill="currentColor"
                    radius={[4, 4, 0, 0]}
                    className="fill-primary"
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">
                Time to Fill by Position
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={timeToFill}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="position" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="days" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">
                Hiring Efficiency
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={hiringEfficiency}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="efficiency" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Reports;
