import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { DataTable } from 'src/components/common/DataTable';
import { Badge } from 'src/components/ui/badge';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import { IndianRupee } from 'lucide-react';

// Cell Components - moved outside main component
const InvoiceIdCell = ({ value, id }) => (
  <div className="text-left">
    <Button variant="link" className="p-0 h-auto font-normal" asChild>
      <Link to={`/customer/invoice/${id}`}>{value}</Link>
    </Button>
  </div>
);

const DateCell = ({ value }) => <div className="text-left">{value || '-'}</div>;

const AmountCell = ({ value }) => (
  <div className="text-left">
    <span className="flex items-center">
      <IndianRupee className="mr-1 h-3 w-3" />
      {value}
    </span>
  </div>
);

const StatusCell = ({ value }) => {
  let badgeColor = 'text-gray-500 border-gray-500';
  if (value === 'Paid') badgeColor = 'text-green-500 border-green-500';
  if (value === 'Overdue') badgeColor = 'text-red-500 border-red-500';

  return (
    <div className="text-left">
      <Badge variant="outline" className={badgeColor}>
        {value}
      </Badge>
    </div>
  );
};

// Column definitions - moved outside main component
const tableColumns = [
  {
    accessorKey: 'id',
    header: 'Invoice ID',
    cell: ({ row }) => (
      <InvoiceIdCell value={row.getValue('id')} id={row.original.id} />
    ),
  },
  {
    accessorKey: 'invoiceDate',
    header: 'Invoice Date',
    cell: ({ row }) => <DateCell value={row.getValue('invoiceDate')} />,
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    cell: ({ row }) => <AmountCell value={row.getValue('amount')} />,
  },
  {
    accessorKey: 'paymentDate',
    header: 'Payment Date',
    cell: ({ row }) => <DateCell value={row.getValue('paymentDate')} />,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => <StatusCell value={row.getValue('status')} />,
  },
];

const InvoiceList = () => {
  const invoices = [
    {
      id: 'INV001',
      invoiceDate: '01 Aug 2023',
      amount: 1000,
      paymentDate: '15 Aug 2023',
      status: 'Paid',
    },
    {
      id: 'INV002',
      invoiceDate: '05 Aug 2023',
      amount: 1500,
      paymentDate: null,
      status: 'Pending',
    },
    {
      id: 'INV003',
      invoiceDate: '10 Aug 2023',
      amount: 2000,
      paymentDate: '25 Aug 2023',
      status: 'Paid',
    },
    {
      id: 'INV004',
      invoiceDate: '15 Aug 2023',
      amount: 1200,
      paymentDate: null,
      status: 'Overdue',
    },
  ];

  const breadcrumbItems = [
    { label: 'Home', href: '/customer/dashboard' },
    { label: 'Invoices', href: '/customer/invoice' },
  ];

  return (
    <>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Invoices
        </h1>
      </div>
      <Breadcrumb items={breadcrumbItems} />

      <div className="items-center justify-center rounded-lg shadow-sm">
        <div className="flex flex-col items-center gap-1 text-center">
          <div className="w-full">
            <DataTable
              columns={tableColumns}
              data={invoices}
              showSearch
              showPagination
              itemsPerPageOptions={[5, 10, 20, 50]}
              defaultItemsPerPage={10}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceList;
