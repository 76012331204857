import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RequirementService } from 'src/services/api/RequirementService';

export const useRequirementForm = ({ defaultValues, onSubmit, reqId }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [proposedJobTitle, setProposedJobTitle] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const form = useForm({ defaultValues });
  const { handleSubmit, setValue, watch } = form;

  const currentJobTitle = watch('jobTitle', '');

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues, form]);

  const handleRoleChange = (newValue) => {
    if (currentJobTitle && currentJobTitle !== newValue) {
      setProposedJobTitle(newValue);
      setIsAlertOpen(true);
    } else {
      setValue('jobTitle', newValue);
    }
  };

  const handleAlertClose = () => setIsAlertOpen(false);

  const handleCancel = () => {
    setIsAlertOpen(false);
    setProposedJobTitle(null);
  };

  const handleContinue = () => {
    if (proposedJobTitle) {
      setValue('jobTitle', proposedJobTitle);
    }
    setIsAlertOpen(false);
    setProposedJobTitle(null);
  };
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const formatRequirementData = (formData) => ({
    organization_id: 1,
    created_by_id: 1,
    title: formData.jobTitle,
    business_unit_id: parseInt(formData.businessUnit, 10),
    career_level: formData.careerLevel,
    detailed_job_description: formData.description,
    additional_requirements: formData.additionalInstructions || '',
    minimum_salary: parseInt(formData.salary, 10),
    employment_type: formData.employmentType,
    work_experience_id: parseInt(formData.minExperience, 10),
    closing_date: formatDate(formData.closingDate),

    qualification_requirements: [
      {
        qualification_type: formData.minEducation,
        field_of_study:
          formData.fieldsOfStudy?.map((field) => ({
            field_of_study_id: parseInt(field.value, 10),
            is_mandatory: field.isMandatory,
          })) || [],
      },
    ],

    locations:
      formData.locations?.map((loc) => ({
        location_id: parseInt(loc.location, 10),
        headcount: parseInt(loc.count, 10),
      })) || [],

    certifications:
      formData.professionalCertifications?.map((cert) => ({
        certification_id: parseInt(cert.value, 10),
        is_mandatory: cert.isMandatory,
      })) || [],

    skills:
      formData.skills?.map((skill) => ({
        skill_id: parseInt(skill.value, 10),
        is_mandatory: skill.isMandatory,
      })) || [],
  });

  const handleNextClick = handleSubmit(
    async (data) => {
      try {
        setIsSubmitting(true);
        setSubmitError(null);

        const updData = formatRequirementData(data);
        // const response = await RequirementService.createRequirement(updData);
        let response;

        if (reqId) {
          // Update existing requirement
          response = await RequirementService.updateRequirement(updData, reqId);
        } else {
          // Create new requirement
          response = await RequirementService.createRequirement(updData);
        }

        if (response.success) {
          onSubmit({
            ...data,
            requirementId: reqId || response.data.requirement_id,
          });
        } else {
          throw new Error(response.message || 'Failed to create requirement');
        }
      } catch (error) {
        setSubmitError(error.message);
        console.error('Error creating requirement:', error);
      } finally {
        setIsSubmitting(false);
      }
    },
    (errors) => {
      console.log('Validation errors:', errors);
    }
  );

  return {
    form,
    proposedJobTitle,
    isAlertOpen,
    isSubmitting,
    submitError,
    handleRoleChange,
    handleAlertClose,
    handleCancel,
    handleContinue,
    handleNextClick,
  };
};
